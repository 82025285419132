export const CheckForIndexHTMLChange = async indexHTMLURL => {
  try {
    const resp = await fetch(indexHTMLURL, { method: 'get', mode: 'cors' });
    const text = await resp.text();
    const r = /^.*<script.*\/(main.*\.js).*$/gim.exec(text);
    if (!r || r.length < 2) {
      return;
    }
    const remoteMainScript = r.length > 1 ? r[1] : null;
    if (remoteMainScript === null) {
      return;
    }
    let localMainScript = null;
    const scripts = document.body.getElementsByTagName('script');
    // eslint-disable-next-line no-restricted-syntax
    for (const script of scripts) {
      const rl = /^.*\/(main.*\.js).*$/gim.exec(script.src);
      if (!rl || rl.length < 2) {
        // eslint-disable-next-line no-continue
        continue;
      }
      // eslint-disable-next-line prefer-destructuring
      localMainScript = rl[1];
      break;
    }
    if (localMainScript === null) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return remoteMainScript !== localMainScript;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    // eslint-disable-next-line consistent-return
    return false;
  }
};
