import { List, Stack } from '@mui/material';
import React from 'react';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import messages from '../../messages';

export default function NavSectionVertical({ data, sx, intl, ...other }) {
  return (
    <Stack sx={sx} {...other}>
      {data.map(group => {
        const key = group.subheader || group.items[0].title;
        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{`${intl.formatMessage({
                ...messages[group.subheader],
              })}`}</StyledSubheader>
            )}
            {group.items.map(list => (
              <NavList
                disabled={list.disabled}
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
                intl={intl}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
