import { m } from 'framer-motion';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line import/no-unresolved
import { SeverErrorIllustration } from 'assets/Illustrations';
// eslint-disable-next-line import/no-unresolved
import { MotionContainer, varBounce } from 'components/Animate';
import { injectIntl } from 'react-intl';
import { createBrowserHistory } from 'history';
import messages from '../Default/messages';

const history = createBrowserHistory();

const Page500Raw = ({
  header,
  message,
  buttonFunc,
  buttonText,
  intl,
  clearError,
  error,
}) => {
  const props = {
    header:
      (header && intl.formatMessage(messages[header])) ||
      '500 Internal Server Error',
    message: message || intl.formatMessage(messages.errorTryLater),
    buttonText,
    buttonFunc,
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(
    () => () => {
      clearError && clearError();
    },
    [clearError],
  );

  return (
    <MotionContainer
      style={{ textAlign: 'center', marginBottom: '400px', marginTop: '100px' }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          {props.header}
        </Typography>
      </m.div>
      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          {props.message}
        </Typography>
      </m.div>
      <m.div variants={varBounce().in}>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>
      {error && (
        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.error', margin: '20px 20px 20px' }}>
            {error && intl.formatMessage(messages.errorIntro)}
          </Typography>
        </m.div>
      )}
      {buttonFunc && (
        <Button
          style={{ marginBottom: '50px' }}
          onClick={buttonFunc}
          size="large"
          variant="contained"
        >
          {props.buttonText}
        </Button>
      )}
      <Button
        style={{
          marginBottom: '50px',
          marginLeft: '10px',
          marginRight: '10px',
        }}
        onClick={refreshPage}
        size="large"
        variant="contained"
      >
        {intl.formatMessage(messages.refresh)}
      </Button>
      {
        <Button
          style={{
            marginBottom: '50px',
            marginLeft: '10px',
            marginRight: '10px',
          }}
          onClick={
            /* eslint-disable-next-line no-restricted-globals */
            () => {
              history.goBack();
              clearError();
            }
          }
          size="large"
          variant="contained"
        >
          {intl.formatMessage(messages.goBack)}
        </Button>
      }
    </MotionContainer>
  );
};

export const Page500 = injectIntl(Page500Raw);
