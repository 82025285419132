import React, { useState, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { useSettingsContext } from 'components/Settings';
import scriptLoader from 'react-async-script-loader';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { useInjectReducer } from 'utils/injectReducer';
import { getVendorScriptsSuccess } from './actions';
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import reducer from './reducer';

export function DashboardLayout(props) {
  useInjectReducer({ key: 'dashboard', reducer });

  const { themeLayout } = useSettingsContext();
  useEffect(() => {
    if (props.isScriptLoadSucceed) {
      props.getVendorScriptsSuccess();
    }
  }, [props.isScriptLoaded]);

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = (
    <NavVertical openNav={open} onCloseNav={handleClose} intl={props.intl} />
  );

  if (isNavMini) {
    return (
      <>
        <Header
          onOpenNav={handleOpen}
          language={props.language}
          changeLocale={props.changeLocale}
          intl={props.intl}
        />
        <Box
          style={{ background: '#fafdff' }}
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini intl={props.intl} /> : renderNavVertical}
          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header
        onOpenNav={handleOpen}
        language={props.language}
        changeLocale={props.changeLocale}
        intl={props.intl}
      />
      <Box
        style={{ background: '#fafdff' }}
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    getVendorScriptsSuccess: () => dispatch(getVendorScriptsSuccess()),
    changeLocale: input => dispatch(changeLocale(input)),
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  scriptLoader([
    'https://js.stripe.com/v3/',
    `https://maps.googleapis.com/maps/api/js?key=${
      process.env.GOOGLE_MAPS_API_KEY
    }`,
  ]),
  withConnect,
  memo,
  injectIntl,
)(DashboardLayout);
