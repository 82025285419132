export default function Stepper() {
  return {
    MuiStepConnector: {
      styleOverrides: {
        line: {
          border: 'solid 1px #ffffff',
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          border: 'solid 1px #ffffff !important',
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: '#00AB55',
          },
        },
      },
    },
  };
}
