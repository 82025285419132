import React, { useState } from 'react';
import { MenuItem, Stack } from '@mui/material';
import Image from 'components/Image';
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/Animate';
import { ALL_LANGS } from 'config-global';
import messages from '../messages';

const getLangObject = (langs, language) => {
  let languageObject = {};
  langs.forEach(function(langItem) {
    if (langItem.value === language) {
      languageObject = langItem;
    }
  });
  return languageObject;
};

export default function LanguagePopover({ language, changeLocale, intl }) {
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = value => {
    changeLocale(value);
    handleClosePopover();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Image disabledEffect>{getLangObject(ALL_LANGS, language).icon}</Image>
      </IconButtonAnimate>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180 }}
      >
        <Stack spacing={0.75}>
          {ALL_LANGS.map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === language}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image disabledEffect sx={{ width: 28, mr: 2 }}>
                {option.icon}
              </Image>
              {intl.formatMessage({
                ...messages[option.label],
              })}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
