import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, ListItemText, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import { StyledIcon, StyledItem } from './styles';
import messages from '../../messages';

const NavItem = forwardRef(
  ({ item, depth, open, active, isExternalLink, intl, ...other }, ref) => {
    const { title, path, icon, children, disabled, caption } = item;

    const subItem = depth !== 1;
    const renderContent = (
      <StyledItem
        ref={ref}
        open={open}
        depth={depth}
        active={active}
        disabled={disabled}
        {...other}
      >
        {icon && <StyledIcon>{icon}</StyledIcon>}
        <ListItemText
          primary={`${intl.formatMessage({
            ...messages[title],
          })}`}
          primaryTypographyProps={{
            noWrap: true,
            sx: {
              whiteSpace: 'normal',
              width: 72,
              fontSize: 10,
              lineHeight: '16px',
              textAlign: 'center',
              ...(active && {
                fontWeight: 'fontWeightMedium',
              }),
              ...(subItem && {
                fontSize: 14,
                width: 'auto',
                textAlign: 'left',
              }),
            },
          }}
        />
        {caption && (
          <Tooltip
            enterTouchDelay={350}
            title={`${intl.formatMessage({
              ...messages[caption],
            })}`}
            arrow
            placement="right"
          >
            <Iconify
              icon="eva:info-outline"
              width={16}
              sx={{
                top: 11,
                left: 6,
                position: 'absolute',
              }}
            />
          </Tooltip>
        )}
        {!!children && (
          <Iconify
            width={16}
            icon="eva:chevron-right-fill"
            sx={{
              top: 11,
              right: 6,
              position: 'absolute',
            }}
          />
        )}
      </StyledItem>
    );

    const renderItem = () => {
      // ExternalLink
      if (isExternalLink)
        return (
          <Link
            disabled={disabled}
            href={item.disabled ? '#' : path}
            target="_blank"
            rel="noopener"
            underline="none"
          >
            {renderContent}
          </Link>
        );

      // Default
      return (
        <Link
          disabled={disabled}
          component={RouterLink}
          to={item.disabled ? '#' : path}
          underline="none"
        >
          {renderContent}
        </Link>
      );
    };

    return <> {renderItem()} </>;
  },
);

export default NavItem;
