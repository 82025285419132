import produce from 'immer';
import {
  CREATE_CUSTOMER_SESSION,
  CREATE_CUSTOMER_SESSION_SUCCESS,
} from './constants';

export const initialState = {
  customerSession: null,
  isGettingCustomerSession: false,
};

/* eslint-disable default-case, no-param-reassign */
const subscriptionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CREATE_CUSTOMER_SESSION:
        draft.isGettingCustomerSession = true;
        break;
      case CREATE_CUSTOMER_SESSION_SUCCESS:
        draft.isGettingCustomerSession = false;
        draft.customerSession = action.payload.clientSecret;
        break;
    }
  });

export default subscriptionReducer;
