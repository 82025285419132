import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@mui/material';
import useActiveLink from 'hooks/useActiveLink';
import NavItem from './NavItem';

export default function NavList({ data, depth, hasChild, intl, disabled }) {
  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        disabled={disabled}
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
        intl={intl}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} intl={intl} />
        </Collapse>
      )}
    </>
  );
}

function NavSubList({ data, depth, intl }) {
  return (
    <>
      {data.map(list => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          intl={intl}
        />
      ))}
    </>
  );
}
