import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  portuguese: {
    id: `${scope}.portuguese`,
    defaultMessage: 'Portuguese',
  },
  english: {
    id: `${scope}.english`,
    defaultMessage: 'English',
  },
});
