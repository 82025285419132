import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import logoIcon from '../../assets/icon.png';
import logoIconWhite from '../../assets/iconWhite2.png';
import logoText from '../../assets/logo.png';

const Logo = forwardRef(
  (
    { disabledLink = false, sx, withText = false, w, h, white, ...other },
    ref,
  ) => {
    const theme = useTheme();

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    let wx = w;
    let hx = h;

    let img;
    if (withText) {
      img = <img src={logoText} alt="logo" />;
    } else {
      img = <img src={white ? logoIconWhite : logoIcon} alt="logo" />;
    }

    if (!largeScreen) {
      wx = 40;
      hx = 40;
      img = <img src={logoIcon} alt="logo" />;
    }

    return (
      <Box sx={{ width: wx || 40, height: hx || 40, cursor: 'pointer', ...sx }}>
        <Link to="/">{img}</Link>
      </Box>
    );
  },
);
export default Logo;
