import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { memo, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import Button from '@mui/material/Button';
import { goToStripeBilling, User } from 'utils/userUtils';
import reducer from './reducer';
import { useInjectReducer } from '../../utils/injectReducer';
import { getCustomerSession } from './actions';
import {
  makeSelectCustomerSession,
  makeSelectIsGettingCustomerSession,
} from './selectors';
import BuyButton from '../BuyButton';
import { StyledCard } from '../AnimatedCard';
import messages from './messages';
import { RefreshPageLabel } from './components/refreshPageLabel';

function formatEmailWithCountry(email, countryCode) {
  const [localPart, domain] = email.split('@');
  const upperCaseCountryCode = countryCode.toUpperCase();
  const formattedEmail = `${localPart}+location_${upperCaseCountryCode}@${domain}`;
  return formattedEmail;
}

export const PricingTable = ({
  tableId,
  publishableKey,
  clientRefId,
  customerEmail,
  countryCode,
}) => (
  <stripe-pricing-table
    pricing-table-id={tableId}
    publishable-key={publishableKey}
    client-reference-id={clientRefId}
    customer-email={customerEmail}
  />
);

const Subscription = props => {
  useInjectReducer({ key: 'subscription', reducer });
  const theme = useTheme();
  const { user } = props;
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    props.getCustomerSession();
  }, []);

  return (
    <Box sx={{ padding: largeScreen ? '30px' : '0px' }}>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '25px',
        }}
      />
      <StyledCard auto largeScreen={largeScreen} hoverShadow={10}>
        {!props.isGettingCustomerSession && (
          <>
            <div>
              <Typography
                style={{
                  fontSize: '1.3rem',
                  padding: largeScreen ? '25px' : '10px',
                  fontWeight: '700',
                  textAlign: largeScreen ? 'left' : 'center',
                }}
              >
                {props.intl.formatMessage(messages.purchaseAditionalCredits)}
              </Typography>
            </div>
            <div
              style={{
                margin: largeScreen ? '5px' : '2px',
                padding: largeScreen ? '10px' : '3px',
                width: '100%',
                textAlign: 'center',
                minHeight: '300px',
                placeContent: 'space-between center',
                display: largeScreen ? 'flex' : 'block',
              }}
            >
              <>
                <BuyButton
                  buyButtonId={process.env.STRIPE_BUY_BUTTON_ID_PACK_1}
                  customerSession={props.customerSession}
                  user={user}
                />
                <BuyButton
                  buyButtonId={process.env.STRIPE_BUY_BUTTON_ID_PACK_5}
                  customerSession={props.customerSession}
                  user={user}
                />
                <BuyButton
                  buyButtonId={process.env.STRIPE_BUY_BUTTON_ID_PACK_10}
                  customerSession={props.customerSession}
                  user={user}
                />
              </>
            </div>
          </>
        )}
        <RefreshPageLabel intl={props.intl} />
      </StyledCard>
      {(User.isFree(user) || User.isPaid(user)) && (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Typography
            style={{ fontSize: '2rem', margin: '30px', fontWeight: '30px' }}
          >
            {props.intl.formatMessage(messages.or)}
          </Typography>
        </div>
      )}
      {(User.isFree(user) || User.isPaid(user)) && (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Typography style={{ fontSize: '1rem', marginBottom: '20px' }}>
            {props.intl.formatMessage(messages.wishUnlimitedCredits)}
          </Typography>
        </div>
      )}
      {User.isFree(user) && (
        <StyledCard auto largeScreen={largeScreen} hoverShadow={10}>
          <div>
            <Typography
              style={{
                fontSize: '1.3rem',
                padding: largeScreen ? '25px' : '10px',
                fontWeight: '700',
                textAlign: largeScreen ? 'left' : 'center',
              }}
            >
              {props.intl.formatMessage(messages.enrollSubscription)}
            </Typography>
          </div>
          <div
            style={{
              margin: largeScreen ? '5px' : '2px',
              padding: largeScreen ? '10px' : '3px',
              width: '100%',
              textAlign: 'center',
              minHeight: '400px',
              placeContent: 'space-between center',
              display: largeScreen ? 'flex' : 'block',
            }}
          >
            <PricingTable
              tableId={process.env.STRIPE_PRICING_TABLE_ID}
              publishableKey={process.env.STRIPE_KEY}
              clientRefId={user.stripeCustomerId}
              customerEmail={user.email}
              countryCode={props.intl.locale}
            />
          </div>
          <RefreshPageLabel intl={props.intl} />
        </StyledCard>
      )}
      {User.isPaid(user) && (
        <>
          <StyledCard auto largeScreen={largeScreen} hoverShadow={10}>
            <div>
              <Typography
                style={{
                  fontSize: '1.3rem',
                  padding: largeScreen ? '25px' : '10px',
                  fontWeight: '700',
                  textAlign: largeScreen ? 'left' : 'center',
                }}
              >
                {props.intl.formatMessage(messages.enrollSubscription)}
              </Typography>
            </div>
            <div
              style={{
                margin: largeScreen ? '5px' : '2px',
                padding: largeScreen ? '10px' : '3px',
                width: '100%',
                textAlign: 'center',
                placeContent: 'space-between center',
                display: largeScreen ? 'flex' : 'block',
              }}
            >
              {!props.isGettingCustomerSession && (
                <>
                  <BuyButton
                    buyButtonId={
                      process.env.STRIPE_BUY_BUTTON_ID_SUBSCRIPTION_YEARLY
                    }
                    customerSession={props.customerSession}
                    user={user}
                  />
                  <BuyButton
                    buyButtonId={
                      process.env.STRIPE_BUY_BUTTON_ID_SUBSCRIPTION_MONTHLY
                    }
                    customerSession={props.customerSession}
                    user={user}
                  />
                </>
              )}
            </div>
            <RefreshPageLabel intl={props.intl} />
          </StyledCard>
          <div
            style={{
              marginTop: '20px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Typography style={{ fontSize: '1rem' }}>
              {props.intl.formatMessage(messages.managePayments)}
            </Typography>
            <Button
              style={{ margin: '30px' }}
              variant="contained"
              onClick={() => goToStripeBilling(user)}
            >
              {props.intl.formatMessage(messages.goToBilling)}
            </Button>
          </div>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  customerSession: makeSelectCustomerSession(),
  isGettingCustomerSession: makeSelectIsGettingCustomerSession(),
});

function mapDispatchToProps(dispatch) {
  return {
    getCustomerSession: () => dispatch(getCustomerSession()),
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
  injectIntl,
)(Subscription);
