import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Tooltip, Link, ListItemText } from '@mui/material';
import Iconify from 'components/Iconify';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import messages from '../../messages';

export default function NavItem({
  item,
  depth,
  open,
  active,
  intl,
  isExternalLink,
  ...other
}) {
  const { title, path, icon, info, children, disabled, caption } = item;

  const subItem = depth !== 1;

  const comingSoonString =
    (disabled && ` - ${intl.formatMessage(messages.soon)}`) || '';

  const renderContent = (
    <StyledItem
      depth={depth}
      active={active}
      disabled={disabled}
      caption={!!caption}
      {...other}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}
      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}
      <ListItemText
        disabled={disabled}
        primary={`${intl.formatMessage({
          ...messages[title],
        })} ${comingSoonString}`}
        secondary={
          caption && (
            <Tooltip
              enterTouchDelay={350}
              title={`${intl.formatMessage({
                ...messages[caption],
              })}`}
              placement="top-start"
            >
              <span>{`${intl.formatMessage({
                ...messages[caption],
              })}`}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />
      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}
      {!!children && (
        <Iconify
          width={16}
          icon={
            open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'
          }
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link
          disabled={disabled}
          href={path}
          target="_blank"
          rel="noopener"
          underline="none"
        >
          {renderContent}
        </Link>
      );
    // Has child
    if (children) {
      return renderContent;
    }
    // Default
    return (
      <Link
        disabled={disabled}
        component={RouterLink}
        to={path}
        underline="none"
      >
        {renderContent}
      </Link>
    );
  };

  return <> {renderItem()} </>;
}
