import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectLanguage = state => {
  if (state.language) {
    localStorage.setItem('language', state.language.locale);
    return state.language;
  }
  localStorage.setItem('language', initialState.locale);
  return initialState;
};

const makeSelectLocale = () =>
  createSelector(
    selectLanguage,
    languageState => languageState.locale,
  );

export { selectLanguage, makeSelectLocale };
