import { m } from 'framer-motion';
import React, { forwardRef } from 'react';
import { Box, Fab } from '@mui/material';

const FabButtonAnimate = forwardRef(
  ({ size = 'large', children, sx, sxWrap, ...other }, ref) => (
    <AnimateWrap size={size} sxWrap={sxWrap}>
      <Fab ref={ref} size={size} sx={sx} {...other}>
        {children}
      </Fab>
    </AnimateWrap>
  ),
);

export default FabButtonAnimate;

const varSmall = {
  hover: { scale: 1.07 },
  tap: { scale: 0.97 },
};

const varMedium = {
  hover: { scale: 1.06 },
  tap: { scale: 0.98 },
};

const varLarge = {
  hover: { scale: 1.05 },
  tap: { scale: 0.99 },
};

function AnimateWrap({ size, children, sxWrap }) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex',
        ...sxWrap,
      }}
    >
      {children}
    </Box>
  );
}
