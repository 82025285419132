export const ACTUATOR_HEALTH_PATH = '/actuator/health';

export const LOGIN_PATH = '/login';
export const NEW_USER_PATH = '/users';
export const UPDATE_USER_PATH = '/users';
export const GET_USER_BY_USERNAME_PATH = '/users';
export const GET_CREDITS_BY_USERNAME_PATH = '/user-status';
export const LOGOUT_PATH = '/logout';
export const GET_USER_TYPES_PATH = '/users/types';
export const GET_CLIENTS_PATH = '/clients';

export const ANALYTICS_CI_PATH = '/analytics-ci';

export const LOCATIONS_FULL_NAMES_PATH = '/locations/full-names';
export const PARISHES_FULL_NAMES_PATH = '/parishes/full-names';

export const LOCATIONS_PATH = '/locations';

export const GET_ANALYSIS_PATH = '/analysis';
export const ANALYSE_EXISTING_PROPERTY_PATH =
  '/analysis-with-existing-property';
export const ANALYSE_PATH = '/analyse';
export const ANALYSE_NEW_PROPERTY_PATH = '/analyse-with-new-property';

export const GET_REPORT_PATH = '/reports';
export const DELETE_REPORT_PATH = '/reports';

export const GET_REPORT_BY_ID_PATH = '/reports';
export const UNSAHRE_REPORT_PATH = '/unshare-report';
export const SHARE_REPORT_PATH = '/share-report';

export const GET_PROPERTIES_ANALYSIS_BY_ID_PATH = '/properties-analysis';

export const TYPOLOGIES_PATH = '/typologies';

export const PROPERTIES_PATH = '/properties';

export const NEW_PROPERTY_PATH = '/properties';
export const NEW_PROPERTY_PATH_ANALYSIS = '/analysis-with-new-property';

export const PROPERTIES_TYPES_PATH = '/properties/types';
export const PROPERTIES_PROCESS_URL = '/properties/process-url';
export const PROPERTIES_FEATURES_PATH = '/properties/features';
export const PROPERTIES_DIVISIONS_PATH = '/properties/divisions';
export const PROPERTIES_STATUS_PATH = '/properties/status';
export const PROPERTIES_TYPOLOGIES_PATH = '/properties/typologies';

export const GET_ENERGY_EFFICIENCY_RATINGS_PATH =
  'properties/energy-efficiency-ratings';
export const GET_PROPERTIES_CONDITIONS_PATH = '/properties/conditions';

export const ACQUISITION_TYPES_PATH = '/acquisition-types';
export const ACQUISITION_STRUCTURES_PATH = '/acquisition-structures';

export const PERCENTILES_PATH = '/percentiles';

export const IRS_CATEGORIES_PATH = '/irs-categories';
export const IRS_CATEGORY_REGIONS_PATH = '/irs-category-regions';
export const IRS_DEPENDENTS_LIST_PATH = '/irs-dependents';
export const CREATE_CUSTOMER_SESSION_PATH = '/create-customer-session';
