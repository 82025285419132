import React from 'react';
import { FormProvider as Form } from 'react-hook-form';

export default function FormProvider({ children, onSubmit, methods, name }) {
  return (
    <Form {...methods}>
      <form id={name} name={name} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}
