import { m } from 'framer-motion';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line import/no-unresolved
import { MotionContainer, varBounce } from 'components/Animate';
// eslint-disable-next-line import/no-unresolved
import { PageNotFoundIllustration } from 'assets/Illustrations';
import { injectIntl } from 'react-intl';
import { createBrowserHistory } from 'history';
import messages from '../Default/messages';

const history = createBrowserHistory();

const Page404Raw = ({ header, message, buttonText, intl, clearError }) => {
  const props = {
    header: header || intl.formatMessage(messages.title),
    message: message || intl.formatMessage(messages.notFound),
    buttonText: buttonText || 'goHome',
    buttonFunc: () => (window.location.href = '/dashboard'),
  };

  useEffect(
    () => () => {
      clearError && clearError();
    },
    [clearError],
  );

  return (
    <MotionContainer
      style={{ textAlign: 'center', paddingBottom: '50px', paddingTop: '50px' }}
    >
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          {props.header}
        </Typography>
      </m.div>
      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          {props.message}
        </Typography>
      </m.div>
      <m.div variants={varBounce().in}>
        <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>
    </MotionContainer>
  );
};

/* {props.buttonFunc && (
  <Button
    onClick={() => {
      props.buttonFunc();
      // eslint-disable-next-line no-unused-expressions
      clearError && clearError();
    }}
    size="large"
    variant="contained"
  >
    {messages[props.buttonText] &&
      intl.formatMessage(messages[props.buttonText])}
  </Button>
)}
{
  <Button
    style={{
      marginLeft: '35px',
      marginRight: '10px',
    }}
    onClick={
      /!* eslint-disable-next-line no-restricted-globals *!/
      () => {
        history.goBack();
        clearError && clearError();
      }
    }
    size="large"
    variant="contained"
  >
    {intl && intl.formatMessage(messages.goBack)}
  </Button>
} */

export const Page404 = injectIntl(Page404Raw);
