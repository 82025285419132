import { createBrowserHistory } from 'history';
import axios from 'axios';
import { PATH_AUTH } from 'routes/paths';
import { noAuthPages } from 'routes';

const history = createBrowserHistory();

const alteredResponse = (url, response) => {
  if (url === true) {
    const responseAltered = response;
    // responseAltered.data.user.credits = 0;
    // responseAltered.data.user.customerId = '12345';
    // responseAltered.data.user.subscribed = true;
    return responseAltered;
  }
  return response;
};

// Adjust getClient to accept dispatch
const getClient = (
  component = null,
  dispatch,
  scope = 'app.containers.default',
  noTriggerGlobalError,
) => {
  const options = {};
  switch (component) {
    case 'PROPERTY_MANAGER_PUBLIC':
      options.baseURL = process.env.PROPERTY_MANAGER;
      options.headers = {
        'Accept-Language': localStorage.getItem('language'),
      };
      break;
    case 'PROPERTY_MANAGER':
      options.baseURL = process.env.PROPERTY_MANAGER;
      options.headers = {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Accept-Language': localStorage.getItem('language'),
      };
      break;
    default:
  }
  const axiosInstance = axios.create(options);

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      // Handle 401 Unauthorized globally
      if (error.response && error.response.status === 401) {
        if (history.location && history.location.pathname) {
          const parts = history.location.pathname.split('/');
          if (!noAuthPages.includes(parts[1])) {
            history.push(PATH_AUTH.signIn);
            localStorage.removeItem('accessToken');
          }
        }
      } else if (dispatch && error.response && !noTriggerGlobalError) {
        // Dispatch a global error action when receiving a 400 or 500 error
        dispatch({
          type: 'GLOBAL_ERROR',
          payload: {
            status: error.response.status,
            message: error.response.data.message || 'An error occurred',
            scope,
          },
        });
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

const handle = error => {
  if (error.response && error.response.status === 401) {
    if (history.location && history.location.pathname) {
      const parts = history.location.pathname.split('/');
      if (!noAuthPages.includes(parts[1])) {
        window.location.href = PATH_AUTH.signIn;
        localStorage.removeItem('accessToken');
      }
    }
  }
  return Promise.reject(error);
};

class ApiClient {
  constructor(
    component = null,
    dispatch = null,
    scope = '',
    noTriggerGlobalError,
  ) {
    this.client = getClient(component, dispatch, scope, noTriggerGlobalError);
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then(response =>
        Promise.resolve(alteredResponse(url === '/users', response)),
      )
      .catch(error => handle(error));
  }

  delete(url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => handle(error));
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then(response =>
        Promise.resolve(alteredResponse(url === '/login', response)),
      )
      .catch(error => handle(error));
  }

  put(url, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => handle(error));
  }

  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => handle(error));
  }
}

export { ApiClient };
