import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Dashboard';

export default defineMessages({
  soon: {
    id: `${scope}.soon`,
    defaultMessage: 'Coming Soon',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'My Reports',
  },
  investments: {
    id: `${scope}.investments`,
    defaultMessage: 'Portfolio analyser',
  },
  clients: {
    id: `${scope}.clients`,
    defaultMessage: 'Clients',
  },
  analysis: {
    id: `${scope}.analysis`,
    defaultMessage: 'Analysis',
  },
  analysisList: {
    id: `${scope}.analysisList`,
    defaultMessage: 'My Analyses',
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  general: {
    id: `${scope}.general`,
    defaultMessage: 'General',
  },
  faq: {
    id: `${scope}.faq`,
    defaultMessage: "FAQ's",
  },
  properties: {
    id: `${scope}.properties`,
    defaultMessage: 'My Properties',
  },
  management: {
    id: `${scope}.management`,
    defaultMessage: 'Management',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  portuguese: {
    id: `${scope}.portuguese`,
    defaultMessage: 'Portuguese',
  },
  english: {
    id: `${scope}.english`,
    defaultMessage: 'English',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start',
  },
});
