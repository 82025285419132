import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Subscription';

export default defineMessages({
  purchaseAditionalCredits: {
    id: `${scope}.purchaseAditionalCredits`,
    defaultMessage: 'Purchase additional Credits',
  },
  wishUnlimitedCredits: {
    id: `${scope}.wishUnlimitedCredits`,
    defaultMessage:
      'If you wish to have unlimited credits, pick a subscription model that suits you.',
  },
  refreshPageLabel: {
    id: `${scope}.refreshPageLabel`,
    defaultMessage:
      'Please refresh the page after performing any changes to your account.',
  },
  enrollSubscription: {
    id: `${scope}.enrollSubscription`,
    defaultMessage: 'Enroll a Subscription',
  },
  managePayments: {
    id: `${scope}.managePayments`,
    defaultMessage: 'Use the button below to manage your payments',
  },
  goToBilling: {
    id: `${scope}.goToBilling`,
    defaultMessage: 'Go to Billing',
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: 'Or',
  },
});
