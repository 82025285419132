export { default as SeoIllustration } from './SeoIllustration';
export { default as UploadIllustration } from './UploadIllustration';
export { default as BookingIllustration } from './BookingIllustration';
export { default as BackgroundIllustration } from './BackgroundIllustration';
export { default as CheckInIllustration } from './CheckInIllustration';
export { default as CheckOutIllustration } from './CheckOutIllustration';
export { default as ForbiddenIllustration } from './ForbiddenIllustration';
export { default as SeverErrorIllustration } from './SeverErrorIllustration';
export { default as MotivationIllustration } from './MotivationIllustration';
export { default as ComingSoonIllustration } from './ComingSoonIllustration';
export { default as MaintenanceIllustration } from './MaintenanceIllustration';
export {
  default as PageNotFoundIllustration,
} from './PageNotFoundIllustration';
export {
  default as OrderCompleteIllustration,
} from './OrderCompleteIllustration';
export {
  default as UpgradeStorageIllustration,
} from './UpgradeStorageIllustration';
