import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths';
import { useAuthContext } from 'containers/AuthenticationProvider/useAuthContext';
import { CustomAvatar } from 'components/CustomAvatar';
import MenuPopover from 'components/MenuPopover';
import { IconButtonAnimate } from 'components/Animate';
import messages from '../messages';

const OPTIONS = [
  {
    label: 'home',
    linkTo: PATH_DASHBOARD.start,
  },
  {
    label: 'account',
    linkTo: PATH_DASHBOARD.account,
  },
];

export default function AccountPopover({ intl }) {
  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = event => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = path => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          alt={user && user.firstName}
          name={user && user.firstName}
        />
      </IconButtonAnimate>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user && `${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user && user.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {OPTIONS.map(option => (
            <MenuItem
              key={option.label}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {intl.formatMessage({
                ...messages[option.label],
              })}
            </MenuItem>
          ))}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {intl.formatMessage({
            ...messages.logout,
          })}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
