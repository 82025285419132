import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

export function useRemoveTrailingSlash() {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const path = location.pathname;
    if (path !== '/' && path.endsWith('/')) {
      const newPath = location.pathname.slice(0, -1);
      navigate(newPath, { replace: true });
    }
  }, [location, navigate]);
}
