import styled from 'styled-components';
import { Card } from '@mui/material';

export const HoverOpacity = styled.div`
  opacity: ${props =>
    props.largeScreen ? 0 : 1}; // Opacity is 0 only if largeScreen is true
  transition: opacity 500ms ease;

  ${props =>
    props.largeScreen &&
    `
    .card:hover & {
      opacity: 1;
    }
  `}
`;

export const HoverColor = styled.div`
  color: #0083fc;
  height: 65%;
  border-radius: 20px;
  box-shadow: 0px 10px 0px -10px #0083fc77;
  opacity: ${props => (props.largeScreen ? 0.4 : 0.8)};
  transition: all 0.2s ease-in-out;

  ${props =>
    props.largeScreen &&
    `
    .card:hover & {
      opacity: 0.8;
      color: #0083fc;
      height: 70%;
    }
  `}
`;

const options = {
  shouldForwardProp: prop => prop !== 'hoverShadow',
};

export const StyledCard = styled(Card, options)(({ largeScreen, auto }) => ({
  height: auto ? 'auto' : largeScreen ? '270px' : '270px',
  ':hover': {
    boxShadow: '0px 4px 32px 0px #0083FC77',
  },
}));
