export default function Collapse(theme) {
  return {
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          width: '100%',
        },
      },
    },
  };
}
