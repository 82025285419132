const isSubscribed = user => user && user.subscribed === true;

const isFree = user => {
  if (!user) {
    throw new Error('User is required');
  }
  return user.stripeCustomerId === undefined;
};

const isPaid = user => {
  if (!user) {
    throw new Error('User is required');
  }
  return user.stripeCustomerId;
};

const canAnalyse = user => isSubscribed(user) || user.credits > 0;

export const User = {
  isSubscribed,
  isFree,
  isPaid,
  canAnalyse,
};

export const goToStripeBilling = user => {
  const path = `https://billing.stripe.com/p/login/${
    process.env.STRIPE_BILLING_ID
  }?prefilled_email=${user.email}`;

  window.location.href = path;
};
