import React from 'react';
import Typography from '@mui/material/Typography';
import messages from '../messages';

export const RefreshPageLabel = ({ intl }) => (
  <div>
    <Typography
      style={{ paddingLeft: '15px', margin: '8px', fontSize: '12px' }}
    >
      🛈 {intl.formatMessage(messages.refreshPageLabel)}
    </Typography>
  </div>
);
