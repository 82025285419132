import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSubscription = state => state.subscription || initialState;

const makeSelectCustomerSession = () =>
  createSelector(
    selectSubscription,
    substate => substate.customerSession,
  );

const makeSelectIsGettingCustomerSession = () =>
  createSelector(
    selectSubscription,
    substate => substate.isGettingCustomerSession,
  );

export { makeSelectCustomerSession, makeSelectIsGettingCustomerSession };
