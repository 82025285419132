import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobalErrorReducerDomain = state =>
  state.globalHandling || initialState;

const selectError = () => initialState;

const makeSelectError = () =>
  createSelector(
    selectGlobalErrorReducerDomain,
    substate => substate.error,
  );

export { selectError, makeSelectError };
