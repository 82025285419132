import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from 'containers/AuthenticationProvider/AuthGuard';
import GuestGuard from 'containers/AuthenticationProvider/GuestGuard';
import DashboardLayout from 'layouts/Dashboard';
import CompactLayout from 'layouts/Compact';
import MainLayout from 'layouts/Main';
import { wrapUseRoutes } from '@sentry/react';
import {
  AccountPage,
  AnalysisPage,
  ClientsEditPage,
  ClientsListPage,
  ClientsNewPage,
  DashboardPage,
  FaqPage,
  HomePage,
  InvestmentsPage,
  Page404,
  PricingPage,
  PrivacyPage,
  PropertiesEditPage,
  PropertiesListPage,
  PropertiesNewPage,
  ReportsPage,
  ReportsWrapper,
  AnalysisListPage,
  SignInPage,
  SignUpPage,
  StartPage,
  TermsPage,
} from './elements';
import { PATH_DASHBOARD } from './paths';

const useSentryRoutes = wrapUseRoutes(useRoutes);

export default function Router() {
  return useSentryRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'signin',
          element: (
            <GuestGuard>
              <SignInPage />
            </GuestGuard>
          ),
        },
        {
          path: 'signup',
          element: (
            <GuestGuard>
              <SignUpPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <SignInPage /> },
        { path: 'register-unprotected', element: <SignUpPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_DASHBOARD.start} replace />,
          index: true,
        },
        { path: 'app', element: <DashboardPage /> },
        { path: 'investments', element: <InvestmentsPage /> },
        {
          path: 'analysis',
          children: [{ path: ':id', element: <AnalysisPage /> }],
        },
        {
          path: 'reports',
          element: <ReportsPage />,
        },
        {
          path: 'start',
          element: <StartPage />,
        },
        { path: 'account', element: <AccountPage /> },
        {
          path: 'clients',
          children: [
            {
              element: <Navigate to="/dashboard/clients/list" replace />,
              index: true,
            },
            { path: 'list', element: <ClientsListPage /> },
            { path: 'new', element: <ClientsNewPage /> },
            { path: 'edit/:id', element: <ClientsEditPage /> },
          ],
        },
        {
          path: 'properties',
          children: [
            {
              element: <Navigate to="/dashboard/properties/list" replace />,
              index: true,
            },
            { path: 'list', element: <PropertiesListPage /> },
            { path: 'new', element: <PropertiesNewPage /> },
            { path: 'edit/:id', element: <PropertiesEditPage /> },
          ],
        },
        {
          path: 'analysis',
          children: [
            {
              element: <Navigate to="/dashboard/analysis/list" replace />,
              index: true,
            },
            { path: 'list', element: <AnalysisListPage /> },
          ],
        },
      ],
    },
    {
      path: 'reports',
      element: <MainLayout logged />,
      children: [
        {
          path: ':id',
          element: (
            <ReportsWrapper>
              <AnalysisPage />
            </ReportsWrapper>
          ),
        },
      ],
    },
    {
      element: <MainLayout />,
      children: [{ path: 'privacy', element: <PrivacyPage /> }],
    },
    {
      element: <MainLayout />,
      children: [{ path: 'terms', element: <TermsPage /> }],
    },
    {
      element: <MainLayout />,
      children: [{ path: 'pricing', element: <PricingPage /> }],
    },
    {
      element: <MainLayout />,
      children: [{ element: <HomePage />, index: true }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

export const noAuthPages = ['pricing', 'terms', 'privacy', 'reports', 'faqs'];
