import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Homepage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Homepage container!',
  },
  primaryButton: {
    id: `${scope}.primaryButton`,
    defaultMessage: 'Start Free Trial',
  },
  secondButton: {
    id: `${scope}.secondButton`,
    defaultMessage: 'Log In',
  },
  footerDescription: {
    id: `${scope}.footer.footerDescription`,
    defaultMessage: `Analyse Real Estate Investments in Seconds. Use Artificial Intelligence to quickly assess the profitability of your property, with market data that is always up-to-date and accurate.`,
  },
  contactUs: {
    id: `${scope}.footer.contactUs`,
    defaultMessage: `Contact Us`,
  },
  faq: {
    id: `${scope}.footer.faq`,
    defaultMessage: `FAQs`,
  },
  legal: {
    id: `${scope}.footer.legal`,
    defaultMessage: `Legal`,
  },
  terms: {
    id: `${scope}.footer.terms`,
    defaultMessage: `Terms and Conditions`,
  },
  privacy: {
    id: `${scope}.footer.privacy`,
    defaultMessage: `Privacy Policy`,
  },
  contact: {
    id: `${scope}.footer.contact`,
    defaultMessage: `Contact`,
  },
  rights: {
    id: `${scope}.footer.rights`,
    defaultMessage: `© 2024. All rights reserved`,
  },
  footerEmail: {
    id: `${scope}.footer.email`,
    defaultMessage: `info@revalue.pt`,
  },
  location: {
    id: `${scope}.footer.location`,
    defaultMessage: `Lisbon, Portugal`,
  },
  revalue: {
    id: `${scope}.footer.revalue`,
    defaultMessage: `Revalue`,
  },
  productFeaturesTitle: {
    id: `${scope}.productFeaturesTitle`,
    defaultMessage: 'How can Revalue help you?',
  },
  productFeaturesDescription: {
    id: `${scope}.productFeaturesDescription`,
    defaultMessage:
      'Through artificial intelligence, we generate personalized and highly effective investment analyses tailored to your specific needs.',
  },
  dashboardTitle: {
    id: `${scope}.dashboardTitle`,
    defaultMessage: 'Personalized Investment Analyses',
  },
  dashboardDescription: {
    id: `${scope}.dashboardDescription`,
    defaultMessage:
      'Through artificial intelligence, we generate personalized and highly effective investment analyses tailored to your specific needs.',
  },
  mobileIntegrationTitle: {
    id: `${scope}.mobileIntegrationTitle`,
    defaultMessage: 'Save Time',
  },
  mobileIntegrationDescription: {
    id: `${scope}.mobileIntegrationDescription`,
    defaultMessage:
      'Our platform transforms a process that used to take months into just a few minutes, providing all the necessary information for your investment.',
  },
  allPlatformsTitle: {
    id: `${scope}.allPlatformsTitle`,
    defaultMessage: 'Informed Decisions about the Real Estate Market',
  },
  allPlatformsDescription: {
    id: `${scope}.allPlatformsDescription`,
    defaultMessage:
      'We provide valuable current information about the market, offering comprehensive and detailed data.',
  },
  heroTitlePart1: {
    id: `${scope}.heroTitlePart1`,
    defaultMessage: 'Analyse',
  },
  heroTitlePart2: {
    id: `${scope}.heroTitlePart2`,
    defaultMessage: 'Real Estate Investments',
  },
  heroTitlePart3: {
    id: `${scope}.heroTitlePart3`,
    defaultMessage: 'in Seconds',
  },
  heroDescription: {
    id: `${scope}.heroDescription`,
    defaultMessage:
      'Use Artificial Intelligence to quickly assess the profitability of your property, with market data that is always up-to-date and accurate.',
  },
  emailLabel: {
    id: `${scope}.emailLabel`,
    defaultMessage: 'Email',
  },
  emailAriaLabel: {
    id: `${scope}.emailAriaLabel`,
    defaultMessage: 'Enter your email address',
  },
  emailPlaceholder: {
    id: `${scope}.emailPlaceholder`,
    defaultMessage: 'Your email address',
  },
  startNow: {
    id: `${scope}.startNow`,
    defaultMessage: 'Start Free Trial',
  },
  startNowAgreement: {
    id: `${scope}.startNowAgreement`,
    defaultMessage: 'By clicking "Start Free Trial" you agree to our',
  },
  termsConditions: {
    id: `${scope}.termsConditions`,
    defaultMessage: 'Terms & Conditions',
  },
  highlightsTitle: {
    id: `${scope}.highlightsTitle`,
    defaultMessage: 'How does it work?',
  },
  highlightsDescription: {
    id: `${scope}.highlightsDescription`,
    defaultMessage:
      'Explore why our product stands out: adaptability, durability, user-friendly design, and innovation. Enjoy reliable customer support and precision in every detail.',
  },
  adaptablePerformance: {
    id: `${scope}.adaptablePerformance`,
    defaultMessage: '1. Create a property',
  },
  adaptablePerformanceDescription: {
    id: `${scope}.adaptablePerformanceDescription`,
    defaultMessage: 'Create or import a property so you can make an analysis.',
  },
  builtToLast: {
    id: `${scope}.builtToLast`,
    defaultMessage: '2. Choose the Type of Investment',
  },
  builtToLastDescription: {
    id: `${scope}.builtToLastDescription`,
    defaultMessage: 'Buy & Hold? or Flix & Flip?',
  },
  greatUserExperience: {
    id: `${scope}.greatUserExperience`,
    defaultMessage: '3. Get an Investment Analysis in Seconds',
  },
  greatUserExperienceDescription: {
    id: `${scope}.greatUserExperienceDescription`,
    defaultMessage:
      'Receive an analysis with the main metrics of your investment - profit after tax, internal rate of return and estimated current market value.',
  },
  innovativeFunctionality: {
    id: `${scope}.innovativeFunctionality`,
    defaultMessage: '4. Edit your Conditions',
  },
  innovativeFunctionalityDescription: {
    id: `${scope}.innovativeFunctionalityDescription`,
    defaultMessage:
      'You can edit the analysis and customize it to your conditions. Do you have a company? Do you need financing? Are you going to do some work? Everything can be edited with Revalue.',
  },
  reliableSupport: {
    id: `${scope}.reliableSupport`,
    defaultMessage: '5. Access to up-to-date Market Data',
  },
  reliableSupportDescription: {
    id: `${scope}.reliableSupportDescription`,
    defaultMessage:
      'With Revalue, you can access and compare the market value for your house and for rentals in the area.',
  },
  precisionInEveryDetail: {
    id: `${scope}.precisionInEveryDetail`,
    defaultMessage: '6. Share your Report',
  },
  precisionInEveryDetailDescription: {
    id: `${scope}.precisionInEveryDetailDescription`,
    defaultMessage:
      'In the end, you can generate an investment report that you can share with your clients and/or partners.',
  },
  trustedBy: {
    id: `${scope}.trustedBy`,
    defaultMessage: 'Trusted by the best companies',
  },
  testimonialsTitle: {
    id: `${scope}.testimonialsTitle`,
    defaultMessage: 'Testimonials',
  },
  testimonialsDescription: {
    id: `${scope}.testimonialsDescription`,
    defaultMessage: 'See what our customers love about Revalue.',
  },
  testimonial1: {
    id: `${scope}.testimonial1`,
    defaultMessage:
      '“Revalue has completely changed the way I value properties for my clients. Before, I spent hours on Excel, which made the analysis process time-consuming and often inaccurate. Now, I can get a clear picture of a property’s true value in minutes.“',
  },
  testimonial1Name: {
    id: `${scope}.testimonial1Name`,
    defaultMessage: 'António Herédia',
  },
  testimonial1Occupation: {
    id: `${scope}.testimonial1Occupation`,
    defaultMessage: 'Partner & CEO at Moontown',
  },
  testimonial2: {
    id: `${scope}.testimonial2`,
    defaultMessage:
      '“Not only does the platform help me quickly identify investment opportunities that are truly profitable, but it also gives me the confidence to present solid proposals to my clients. It is an indispensable tool for anyone working in the real estate market.“',
  },
  testimonial2Name: {
    id: `${scope}.testimonial2Name`,
    defaultMessage: 'Joaquim Fiadeiro',
  },
  testimonial2Occupation: {
    id: `${scope}.testimonial2Occupation`,
    defaultMessage: 'KW Portugal',
  },
  testimonial3: {
    id: `${scope}.testimonial3`,
    defaultMessage:
      '“With Revalue, I can quickly evaluate different properties and see which ones have the greatest potential for appreciation. What impresses me most is the clarity and variety of the data and the way the insights are presented – it gives me confidence to make more informed decisions and reduce risk in my investments.“',
  },
  testimonial3Name: {
    id: `${scope}.testimonial3Name`,
    defaultMessage: 'Marta Ferreira',
  },
  testimonial3Occupation: {
    id: `${scope}.testimonial3Occupation`,
    defaultMessage: 'Real Estate Investor',
  },
  findOut: {
    id: `${scope}.findOut`,
    defaultMessage: 'Find Your Next Real Estate Investment!',
  },
  callToActionButton: {
    id: `${scope}.callToActionButton`,
    defaultMessage: 'Start Free Trial Now',
  },
  typeapartment: {
    id: `${scope}.typeapartment`,
    defaultMessage: 'Apartment',
  },
  typehouse: {
    id: `${scope}.typehouse`,
    defaultMessage: 'House',
  },
  conditionused: {
    id: `${scope}.conditionused`,
    defaultMessage: 'Used',
  },
  conditionnew: {
    id: `${scope}.conditionnew`,
    defaultMessage: 'New',
  },
  conditiontorenovate: {
    id: `${scope}.conditiontorenovate`,
    defaultMessage: 'To Renovate',
  },
  conditionrenovated: {
    id: `${scope}.conditionrenovated`,
    defaultMessage: 'Renovated',
  },
  conditionconstruction: {
    id: `${scope}.conditionconstruction`,
    defaultMessage: 'In Construction',
  },
  internalRateOfReturn: {
    id: `${scope}.internalRateOfReturn`,
    defaultMessage: 'Internal Rate Of Return',
  },
  profitAfterTax: {
    id: `${scope}.profitAfterTax`,
    defaultMessage: 'Profit After Tax',
  },
  requiredCapital: {
    id: `${scope}.requiredCapital`,
    defaultMessage: 'Required Capital',
  },
  monthlyRent: {
    id: `${scope}.monthlyRent`,
    defaultMessage: 'Monthly Rent',
  },
});
