import React from 'react';
import { Navigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import LoadingScreen from 'components/LoadingScreen';
import { useAuthContext } from './useAuthContext';

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.start} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
