import React, { useEffect } from 'react';

function BuyButton({ buyButtonId, customerSession, user }) {
  useEffect(() => {
    const handleButtonClick = event => {
      const stripeButton = document.getElementById('stripe-buy-button');
      if (stripeButton && stripeButton.contains(event.target)) {
        // window.close();
      }
    };

    window.addEventListener('click', handleButtonClick, true);

    return () => {
      window.removeEventListener('click', handleButtonClick, true);
    };
  }, []);

  return (
    <div style={{ margin: '5px', padding: '20px' }} id="stripe-buy-button">
      <stripe-buy-button
        buy-button-id={buyButtonId}
        publishable-key={process.env.STRIPE_KEY}
        customer-email={customerSession !== '' ? null : user.email}
        customer-session-client-secret={
          customerSession !== '' ? customerSession : null
        }
      />
    </div>
  );
}

export default BuyButton;
