import produce from 'immer';
export const initialState = {
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const globalHandlingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'GLOBAL_ERROR':
        draft.error = action.payload;
        break;
      case 'GLOBAL_ERROR_CLEAR':
        draft.error = false;
        break;
    }
  });

export default globalHandlingReducer;
