import { useLocation, Outlet } from 'react-router-dom';
import React, { memo } from 'react';
import { Box } from '@mui/material';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { getVendorScriptsSuccess } from '../Dashboard/actions';
import Header from './Header';
import Footer from './Footer';

export function MainLayout(props) {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header
        logged={props.logged}
        language={props.language}
        changeLocale={props.changeLocale}
        intl={props.intl}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        <Outlet />
      </Box>
      {!props.logged && <Footer intl={props.intl} />}
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  language: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    getVendorScriptsSuccess: () => dispatch(getVendorScriptsSuccess()),
    changeLocale: input => dispatch(changeLocale(input)),
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
  injectIntl,
)(MainLayout);
