import React, { useState } from 'react';
import Iconify from 'components/Iconify';
import FormProvider, { RHFTextField } from 'components/HookForm';
import {
  Alert,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useAuthContext } from 'containers/AuthenticationProvider/useAuthContext';
import LoginLayout from 'layouts/Login';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { handleError } from 'utils/errorHandling';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from 'routes/paths';
import messages from './messages';

export function SignInPage(props) {
  const { login } = useAuthContext();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(props.intl.formatMessage({ ...messages.inputRequired }))
      .email(props.intl.formatMessage({ ...messages.inputValidEmail })),
    password: Yup.string().required(
      props.intl.formatMessage({ ...messages.inputRequired }),
    ),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async data => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      reset();
      handleError(error, {
        500: () =>
          setError('afterSubmit', {
            ...error,
            message:
              error.response.data.message ||
              props.intl.formatMessage({ ...messages.loginFailed }),
          }),
        default: () =>
          setError('afterSubmit', {
            ...error,
            message: props.intl.formatMessage({ ...messages.loginFailed }),
          }),
      });
    }
  };

  return (
    <>
      <LoginLayout isSignin>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4">
            {props.intl.formatMessage({
              ...messages.signIn,
            })}
          </Typography>
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2">
              {props.intl.formatMessage({
                ...messages.newUser,
              })}
            </Typography>
            <Link
              component={RouterLink}
              to={PATH_AUTH.signUp}
              variant="subtitle2"
            >
              {props.intl.formatMessage({
                ...messages.createAccount,
              })}
            </Link>
          </Stack>
        </Stack>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
          name="signin"
        >
          <Stack spacing={3}>
            {!!errors && !!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
            <RHFTextField
              InputLabelProps={{ shrink: true }}
              name="email"
              label={`${props.intl.formatMessage({
                ...messages.email,
              })} *`}
            />
            <RHFTextField
              name="password"
              label={`${props.intl.formatMessage({
                ...messages.password,
              })} *`}
              type={showPassword ? 'text' : 'password'}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack alignItems="flex-end" sx={{ my: 2 }}>
            {/*            <Link
              component={RouterLink}
              to={PATH_AUTH.resetPassword}
              variant="body2"
              color="inherit"
              underline="always"
            >
              {props.intl.formatMessage({
                ...messages.forgotPassword,
              })}
            </Link> */}
          </Stack>
          <LoadingButton
            fullWidth
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitSuccessful || isSubmitting}
            style={{ marginBottom: '50px' }}
            sx={{
              bgcolor: 'text.primary',
              color: theme =>
                theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
              '&:hover': {
                bgcolor: 'text.primary',
                color: theme =>
                  theme.palette.mode === 'light' ? 'common.white' : 'grey.800',
              },
            }}
          >
            {!isSubmitting &&
              props.intl.formatMessage({
                ...messages.login,
              })}
          </LoadingButton>
        </FormProvider>
      </LoginLayout>
    </>
  );
}

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  injectIntl,
)(SignInPage);
