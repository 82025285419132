import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import localStorageAvailable from 'utils/localStorageAvailable';
import { isValidToken, setSession, setUser } from './utils';
import {
  createUser,
  getCreditsByUsername,
  getUserByUsername,
  loginUser,
  updateUser,
} from './actions';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'SET_USER_SUCCESS') {
    return {
      ...state,
      user: action.payload,
    };
  }

  return state;
};

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem('accessToken')
        : '';

      if (accessToken && isValidToken(accessToken)) {
        const response = await getUserByUsername();

        const userInfoUpdate = response.data;

        setSession(accessToken, userInfoUpdate);

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user: userInfoUpdate,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await loginUser({ email, password });
    const { accessToken, user } = response.data;

    setSession(accessToken, user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  const register = useCallback(async data => {
    const response = await createUser(data);
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  const update = useCallback(async (data, callback) => {
    const response = await updateUser(data);

    dispatch({
      type: 'SET_USER_SUCCESS',
      payload: response.data,
    });

    callback();
  }, []);

  const getUser = async () => {
    const response = await getUserByUsername();

    const userInfoUpdate = response.data;

    setUser(userInfoUpdate);

    dispatch({
      type: 'INITIAL',
      payload: {
        isAuthenticated: true,
        user: userInfoUpdate,
      },
    });
  };

  const getCredits = async () => {
    const response = await getCreditsByUsername();

    const userInfoUpdate = response.data;

    let currentUserData = state.user;

    currentUserData = { ...currentUserData, ...userInfoUpdate };

    dispatch({
      type: 'INITIAL',
      payload: {
        isAuthenticated: true,
        user: currentUserData,
      },
    });
  };

  // LOGOUT
  const logout = useCallback(() => {
    // TODO add logout request
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      getUser,
      getCredits,
      register,
      logout,
      update,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      getUser,
      getCredits,
      register,
      update,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
