import React from 'react';
import { Stack, Box } from '@mui/material';
import { NAV } from 'config-global';
import { hideScrollbarX } from 'utils/cssStyles';
import Logo from 'components/Logo';
import { NavSectionMini } from '../nav-section';
import navConfig from './config-navigation';
import NavToggleButton from './NavToggleButton';

export default function NavMini({ intl }) {
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />
        <NavSectionMini data={navConfig} intl={intl} />
      </Stack>
    </Box>
  );
}
