import React from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import wall2 from 'assets/wall2.jpg';
import { bgGradient } from 'utils/cssStyles';
import { MotionContainer, varFade } from 'components/Animate';
import messages from '../messages';

export default function FaqsHero({ props }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.grey[900], 0.6),
          imgUrl: wall2,
          borderRadius: '15px',
        }),
        height: { md: 560 },
        py: { xs: 10, md: 0 },
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Container component={MotionContainer}>
        <Box
          sx={{
            bottom: { md: 80 },
            position: { md: 'absolute' },
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <div>
            <TextAnimate
              text={props.intl.formatMessage(messages.how)}
              sx={{ color: 'primary.main' }}
              variants={varFade({}).inRight}
            />
            <br />

            <Stack
              spacing={2}
              display="inline-flex"
              direction="row"
              sx={{ color: 'common.white' }}
            >
              <TextAnimate text={props.intl.formatMessage(messages.can)} />
              <TextAnimate text={props.intl.formatMessage(messages.we)} />
              <TextAnimate text={props.intl.formatMessage(messages.help)} />
              <TextAnimate text={props.intl.formatMessage(messages.you)} />
            </Stack>
          </div>

          {/* <m.div variants={varFade({}).in}>
            <TextField
              fullWidth
              placeholder="Search support..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                mt: 5,
                maxWidth: 360,
                [`& .${outlinedInputClasses.root}`]: {
                  bgcolor: 'common.white',
                },
                [`& .${outlinedInputClasses.input}`]: {
                  typography: 'subtitle1',
                },
              }}
            />
          </m.div> */}
        </Box>
      </Container>
    </Box>
  );
}

// ----------------------------------------------------------------------

export function TextAnimate({ text, variants, sx, ...other }) {
  return (
    <Box
      component={m.div}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {text.split('').map((letter, index) => (
        <m.span key={index} variants={variants || varFade({}).inUp}>
          {letter}
        </m.span>
      ))}
    </Box>
  );
}

TextAnimate.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
  variants: PropTypes.object,
};
