import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Page404 } from 'pages/4xx';
import { Page500 } from 'pages/500';
import { makeSelectError } from '../selectors';
import { clearError } from '../actions';
import { ErrorBoundary } from './errorBoundary';

const withErrorHandler = (
  WrappedComponent,
  ignore500 = false,
  ignore404 = false,
) => {
  const WithErrorHandler = ({ hasError, ...props }) => {
    if (hasError) {
      if (hasError.status === 404) {
        if (ignore404) {
          props.clearError();
          return (
            <ErrorBoundary>
              <WrappedComponent {...props} />
            </ErrorBoundary>
          );
        }
        return <Page404 clearError={props.clearError} />;
      }

      if (hasError.status === 500) {
        if (ignore500) {
          props.clearError();
          return (
            <ErrorBoundary>
              <WrappedComponent {...props} />
            </ErrorBoundary>
          );
        }
        return (
          <Page500 clearError={props.clearError} message={hasError.message} />
        );
      }
      return (
        <ErrorBoundary>
          <WrappedComponent {...props} />
        </ErrorBoundary>
      );
    }
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  const mapStateToProps = createStructuredSelector({
    hasError: makeSelectError(),
  });

  function mapDispatchToProps(dispatch) {
    return {
      clearError: () => dispatch(clearError()),
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithErrorHandler);
};

export default withErrorHandler;
