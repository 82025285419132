import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Stack, Toolbar } from '@mui/material';
import { bgBlur } from 'utils/cssStyles';
import useOffSetTop from 'hooks/useOffSetTop';
import useResponsive from 'hooks/useResponsive';
import { HEADER, NAV } from 'config-global';
import Iconify from 'components/Iconify';
import { useSettingsContext } from 'components/Settings';
import LanguagePopover from 'containers/LanguageProvider/components/LanguagePopover';
import Label from 'components/Label';
import { User } from 'utils/userUtils';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountPopover from './AccountPopover';
import { useAuthContext } from '../../../containers/AuthenticationProvider/useAuthContext';
import SubscriptionModal from '../../../components/SubscriptionModal';
import messages from '../../../pages/Default/messages';

export default function Header({ onOpenNav, language, changeLocale, intl }) {
  const theme = useTheme();
  const { user } = useAuthContext();
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const [openSubModal, setOpenSubModal] = React.useState(false);

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const credits = user ? user.credits : 0;

  const renderContent = (
    <>
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {!User.isSubscribed(user) && (
          <Label
            color={!credits ? 'error' : 'primary'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenSubModal(true);
            }}
          >
            {`${credits} ${intl.formatMessage(messages.credits)}`}{' '}
            <CreditCardIcon style={{ height: '15px' }} />
          </Label>
        )}
        <LanguagePopover
          language={language}
          changeLocale={changeLocale}
          intl={intl}
        />
        <AccountPopover intl={intl} />
      </Stack>
      <SubscriptionModal
        intl={intl}
        open={openSubModal}
        handleClose={() => {
          setOpenSubModal(false);
        }}
      />
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
