function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  signIn: path(ROOTS_AUTH, '/signin'),
  signUp: path(ROOTS_AUTH, '/signup'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  privacy: '/privacy',
  terms: '/terms',
  about: '#about-us',
  contact: '#contact-us',
  faqs: '/faqs',
  start: '/start',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  reports: '/reports',
  reportsId: id => `/reports/${id}`,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboards: path(ROOTS_DASHBOARD, '/app'),
  investments: path(ROOTS_DASHBOARD, '/investments'),
  start: path(ROOTS_DASHBOARD, '/start'),
  account: path(ROOTS_DASHBOARD, '/account'),
  faqs: path(ROOTS_DASHBOARD, '/faqs'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  reports: path(ROOTS_DASHBOARD, '/reports'),
  clients: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    new: path(ROOTS_DASHBOARD, '/clients/new'),
    list: path(ROOTS_DASHBOARD, '/clients/list'),
    edit: id => path(ROOTS_DASHBOARD, `/clients/edit/${id}`),
  },
  properties: {
    root: path(ROOTS_DASHBOARD, '/properties'),
    new: path(ROOTS_DASHBOARD, '/properties/new'),
    list: path(ROOTS_DASHBOARD, '/properties/list'),
    edit: id => path(ROOTS_DASHBOARD, `/properties/edit/${id}`),
  },
  analysis: {
    root: path(ROOTS_DASHBOARD, '/analysis'),
    list: path(ROOTS_DASHBOARD, '/analysis/list'),
    id: id => path(ROOTS_DASHBOARD, `/analysis/${id}`),
  },
};
