import { defineMessages } from 'react-intl';

export const scope = 'app.default';

export default defineMessages({
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go Back',
  },
  notFound: {
    id: `${scope}.notFound`,
    defaultMessage:
      'Could not find this resource. Please make sure you typed the correct url and that the resource exists.',
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: 'Refresh Page',
  },
  goHome: {
    id: `${scope}.goHome`,
    defaultMessage: 'Go to Home',
  },
  paidMessage: {
    id: `${scope}.paidMessage`,
    defaultMessage: 'This feature is locked in free trial. To unlock, please',
  },
  subscribe: {
    id: `${scope}.subscribe`,
    defaultMessage: 'Subscribe',
  },
  errorIntro: {
    id: `${scope}.errorIntro`,
    defaultMessage:
      'If you are seeing this page, it means there was a problem with the application. The support team has been notified and will work to fix the problem as soon as possible. We apologize for any inconvenience.',
  },
  errorTryLater: {
    id: `${scope}.errorTryLater`,
    defaultMessage: 'There was an error, please try again later.',
  },
  errorTitle: {
    id: `${scope}.errorTitle`,
    defaultMessage: 'The Application encountered an error',
  },
  outOfCredits: {
    id: `${scope}.outOfCredits`,
    defaultMessage: 'You are out of Credits',
  },
  creditsAndSubscription: {
    id: `${scope}.creditsAndSubscription`,
    defaultMessage: 'Credits & Subscriptions',
  },
  credits: {
    id: `${scope}.credits`,
    defaultMessage: 'Credits',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Resource Not Found',
  },
});
